import React, { useEffect, useState } from 'react'

const WhoWeAre = ({item}) => {
	const [image, setImage] = useState();

	useEffect(() => {
		if(item){
			setImage(item.elements?.find(it => it.type === "images").elements[0])
		}
	}, [item])
  return (
	<div className="section section-x tc-grey">
		<div className="container">
			<div className="row justify-content-between align-items-center gutter-vr-30px">
				<div className="col-xl-7 col-lg-6">
					<div className="image-block">
						<img src={image} alt=""/>
					</div>
				</div>
				<div className="col-xl-5 col-lg-6">
					<div className="text-block text-box-ml mtm-15">
						<h2>{item.title}</h2>
						{item.subtitle && <p className="lead">{item.subtitle}</p>}
						<p dangerouslySetInnerHTML={{ __html: item.description }}></p>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default WhoWeAre