import React, { useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { useNavigate, useParams } from 'react-router-dom'
import MainBanner from '../../components/complex/MainBanner'
import About from '../../components/complex/About'
import Services from '../../components/complex/Services'
import Steps from '../../components/complex/Steps'
import Portfolio from '../../components/complex/Portfolio'
import Partners from '../../components/complex/Partners'
import Faq from '../../components/complex/Faq'
import WhoWeAre from '../../components/complex/WhoWeAre'
import Advantages from '../../components/complex/Advantages'
import { useLangContext } from '../../i18n/ProvideLang'
import Form from '../../components/complex/Form'

const SamplePage = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

    useEffect(() => {
        if(lang){
          navigate(`/${lang}/${slug}`)
        }
    }, [lang])


  return (
    <div>
      {items &&
        items.elements?.map((elem, index) => 
          <div key={index}>
            {elem.type === "opening-5" ?
              <MainBanner item={elem}/>
            :
            elem.type === "advantages" ? 
              <About item={elem}/>
            :
            elem.type === "steps" ?
              elem.is_component ?
              <Services item={elem}/>
              :
              <Steps item={elem}/>
            :
            elem.type === "gallery" ? 
              <Portfolio item={elem}/>
            :
            elem.type === "partners-2" ? 
              <Partners item={elem}/>
            :
            elem.type === "faq" ? 
              <Faq item={elem}/>
            :
            elem.type === "about" ? 
              <WhoWeAre item={elem}/>
            :
            elem.type === "about-2" ? 
              <Steps item={elem} />
            :
            elem.type === "form" ?
              <Form item={elem} dark={elem.is_dark}/>
            :
            elem.type === "advantages-2" ?
              <Advantages item={elem} dark={elem.is_dark}/>
            :
              <></>
            }
          </div>
        )
      }
    </div>
  )
}

export default SamplePage